.pf-Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 1050;
}

.pf-Modal-content {
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 10px;
}

.pf-Modal-open {
  display: block;
}

.pf-Modal-closed {
  display: none;
}

.pf-Modal-body {
  padding: 1em;
}

.pf-Modal-footer {
  text-align: center;
}

.pf-Modal-header {
  text-align: center;
}
